<template>
  <section class="columns is-multiline">
    <section class="cookie-banner-wrapper">
      <article class="column is-8-desktop is-12-mobile">
        <p>
          Nasza strona korzysta również z plików cookie. Możesz określić warunki
          przechowania lub dostęp do plików cookie w Twojej przeglądarce. W celu
          poznania szczegółów zapoznaj się z naszą polityką prywatności.
        </p>
      </article>
      <article class="column is-2-desktop is-12-mobile">
        <button class="button is-reject">ODRZUĆ</button>
      </article>
      <article class="column is-2-desktop is-12-mobile">
        <button class="button" @click="agreePrivacyPolicy(true)">
          AKCEPTUJ
        </button>
      </article>
    </section>
  </section>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'CookieBanner',
  methods: {
    ...mapMutations(['agreePrivacyPolicy']),
  },
};
</script>

<style lang="scss" scoped>
.cookie-banner-wrapper {
  width: 100%;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  z-index: 150;
  padding: 1.5rem;
  align-items: center;
  text-align: justify;
  font-size: 14px;
}

@media only screen and (max-width: 768px) {
  .cookie-banner-wrapper {
    height: 42vh;
  }
}

@media only screen and (min-width: 769px) {
  .cookie-banner-wrapper {
    height: 90px;
    display: flex;
  }
}

.columns {
  margin: 0 auto;
}

.button {
  background-color: $primary;
  border-radius: 0;
  border: 0;
  padding: 1.5rem;
  font-weight: bold;
  font-size: 12px;
  width: 100%;

  &.is-reject {
    background-color: #fff;
    &:hover {
      background-color: $white-bis;
    }
  }
}
</style>
